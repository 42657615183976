@import "../../styles/functions.scss";

.problems {
  :global {
    .ant-table-thead {
      .ant-table-cell {
        &::before {
          width: 0 !important;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row:hover {
        cursor: pointer;

        .ant-table-cell {
          background-color: var(--gray-25) !important;
        }
      }
    }
  }

  &_img {
    display: flex;
    align-items: center;
    gap: rem(8);

    p {
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(20);
      color: #2E90FA;
      margin: 0;
    }

    span {
      width: rem(32);
      height: rem(22);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rem(16);
      color: var(--gray-700);
      background-color: #F9F5FF;
      font-weight: 600;
      font-size: rem(12);
      line-height: rem(18);
    }
  }

  &_status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(141);
    height: rem(28);
    border-radius: rem(16);
    border: rem(1.5) solid transparent;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(20);
    margin: 0 auto;
  }
}

.tabs {
  :global {
    .ant-tabs-nav {
      margin-bottom: 0;
      margin-left: rem(16);
    }
  }
}