@import "../../../../styles/functions.scss";

.modal {
  .classes {
    :global {
      .ant-select-selection-item {
        border-radius: rem(6);
        padding-inline: rem(8);
        border: rem(1) solid var(--gray-300);
        background-color: transparent;
      }
    }
  }
}

.tag {
  display: flex;
  column-gap: rem(5);
  align-items: center;

  img {
    width: 1rem;
    height: 1rem;
  }
}
