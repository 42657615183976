@import '../../../../styles/functions.scss';

.navbar {
  display: flex;
  align-items: center;
  min-height: rem(64);
  padding: 0 1rem;
  border-bottom: rem(1) solid var(--gray-200);
  background-color: var(--white);

  span {
    font-weight: 600;
    font-size: rem(20);
    line-height: rem(30);
    color: var(--gray-800);
  }
}