@import "../../../../styles/functions.scss";

.organization {
  :global {
    .ant-collapse-item {
      > .ant-collapse-header {
        display: flex;
        align-items: center;
        height: rem(48);
        border-radius: rem(6) !important;
        padding: 0 1rem;

        &:hover {
          background-color: var(--gray-50);
        }

        .ant-collapse-expand-icon {
          padding-inline-end: rem(8) !important;
        }
      }

      .ant-collapse-content {
        &-box {
          display: flex;
          flex-direction: column;
          row-gap: rem(8);
          padding: rem(8) 0 !important;
        }
      }
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(6);

    &_left {
      display: flex;
      column-gap: 1rem;

      .name {
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-900);
        margin: 0;
      }

      .classes {
        display: flex;
        align-items: center;
        column-gap: rem(6);

        div {
          display: flex;
          align-items: center;
          column-gap: rem(6);

          span {
            color: var(--gray-600);
          }

          p {
            font-weight: 400;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--gray-600);
            margin: 0;
          }
        }
      }
    }

    &_right {
      display: flex;
      column-gap: 2rem;

      span {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-400);
      }
    }
  }

  .create {
    display: flex;
    align-items: center;
    column-gap: rem(8);
    height: rem(48);
    width: max-content;
    padding: 0 1rem;
    border-radius: rem(6);
    cursor: pointer;
    transition: 0.2s;
    margin-left: 3rem;

    &:hover {
      background-color: var(--gray-50);
    }

    svg path {
      stroke: var(--blue-600);
    }

    p {
      font-weight: 600;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--blue-600);
    }
  }
}
