@import '../../../../../styles/functions.scss';

.modal {
  .form_item_checkbox {
    :global {
      .ant-row {
        flex-direction: row-reverse !important;
        align-items: center;
        justify-content: flex-end;
        column-gap: rem(8);

        .ant-col {
          width: max-content !important;
          padding: unset !important;
          flex-grow: unset !important;
        }
      }
    }
  }
}
