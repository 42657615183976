@import '../../styles/functions.scss';

.accidents {

  :global {
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          background-color: var(--gray-25);

          &::before {
            width: 0 !important;
          }

          .ant-table-filter-column {
            justify-content: flex-start;

            span {
              flex: inherit;
              font-weight: 500;
              font-size: rem(12);
              line-height: rem(18);
              color: var(--gray-600);
            }
          }
        }
      }
    }
  }

  .status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(141);
    height: rem(28);
    border-radius: rem(16);
    border: rem(1.5) solid transparent;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(20);
    margin: 0 auto;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;

    svg {
      padding: rem(10);
      cursor: pointer;
    }
  }
}

.tabs {
  :global {
    .ant-tabs-nav {
      margin-bottom: 0;
      margin-left: rem(16);
    }
  }
}