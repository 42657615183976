@import "../../styles/functions.scss";

.organizations {
  &_table {
    .name {
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-900);
      margin: 0;
    }

    .classes {
      .tag {
        display: inline-flex;
        align-items: center;
        column-gap: rem(5);
        margin-inline-end: rem(6);
        border-radius: rem(6);
        padding-inline: rem(8);
        border: rem(1) solid var(--gray-300);
        background-color: transparent;

        img {
          width: 1rem;
          height: 1rem;
          object-fit: contain;
        }

        span {
          font-weight: 500;
          font-size: rem(14);
          line-height: rem(20);
          color: var(--gray-700);
        }
      }
    }

    .actions {
      display: flex;
      column-gap: rem(4);

      svg {
        cursor: pointer;
        padding: rem(10);
      }
    }
  }
}
