@import '../../styles/functions';

.statistics {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;

  &_left {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: rem(600);
  }

  &_right {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}