@import '../../../styles/functions';

.modal {

  &_body {
    display: flex;
    flex-direction: column;

    .label {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-600);
      margin: 0;
    }

    .value {
      font-weight: 400;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--gray-900);
      margin: 0;
    }

    .message {
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-bottom: 1rem;
      border-bottom: rem(1) solid var(--gray-200);

      .date {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-600);
        margin: 0;
      }
    }

    .phone {
      display: flex;
      flex-direction: column;
      row-gap: rem(8);
      padding-top: 1rem;
    }
  }
}