@import '../../../../styles/functions.scss';

.modal_root {
  :global {
    .ant-modal-content {
      padding: 0 !important;
      overflow: hidden;
    }
  }
}

.modal {
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    align-items: center;
    column-gap: rem(8);
    padding: 1rem rem(24) rem(20);
    border-bottom: rem(1) solid var(--gray-200);

    p {
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(28);
      color: var(--gray-900);
      margin: 0;
    }

    span {
      font-weight: 500;
      font-size: rem(12);
      line-height: rem(18);
      padding: rem(2) rem(8);
      border-radius: 1rem;
      border: rem(1) solid transparent;
    }
  }

  &_body {
    display: flex;

    &_left {
      flex: 1;
      padding: 0 rem(24);

      .info {
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        padding: 1rem 0;

        &:not(:last-child) {
          border-bottom: rem(1) solid var(--gray-200);
        }

        &_item {
          display: flex;
          flex-direction: column;
          row-gap: rem(4);

          span {
            font-weight: 500;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-400);
          }

          p {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--gray-700);
            margin: 0;

            a {
              display: flex;
              align-items: center;

              svg {
                width: rem(20);
                height: rem(20);
              }
            }
          }
        }

        .status {
          display: flex;
          align-items: center;
          column-gap: rem(8);
          width: max-content;
          background: var(--gray-50);
          border-radius: rem(8);
          padding: rem(4) rem(8);

          p {
            font-weight: 500;
            font-size: rem(16);
            line-height: rem(24);
            color: var(--blue-600);
            margin: 0;
          }

          span {
            font-weight: 500;
            font-size: rem(12);
            line-height: rem(18);
            padding: rem(2) rem(8);
            border-radius: 1rem;
            border: rem(1) solid transparent;
          }
        }

        .images {
          display: flex;
          flex-direction: column;
          row-gap: rem(4);

          .title {
            font-weight: 500;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-400);
          }

          &_cont {
            display: flex;
            flex-direction: column;
            row-gap: rem(4);

            .item {
              display: flex;
              align-items: center;

              span {
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(20);
                color: var(--gray-700);
              }

              p {
                font-weight: 500;
                font-size: rem(14);
                line-height: rem(20);
                color: var(--blue-500);
                margin: 0;
                cursor: pointer;
              }
            }
          }

          img {
            display: none;
          }
        }
      }

      .form {
        padding-top: 1rem;

        :global {
          .ant-picker {
            height: rem(44);
            width: 100%;
            border: rem(1) solid var(--gray-300);

            &.ant-picker-status-error {
              border-color: #ff7875;
            }
          }
        }
      }
    }

    &_right {
      display: flex;
      flex-direction: column;
      row-gap: rem(8);
      padding: rem(12) rem(24);
      max-height: 70vh;
      overflow: auto;
      width: rem(456);
      max-width: rem(456);
      box-sizing: border-box;
      background: var(--gray-50);

      .events {
        display: flex;
        flex-direction: column;
        row-gap: rem(8);

        &_item {
          display: flex;
          justify-content: space-between;
          column-gap: 2rem;

          span {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-400);
          }
        }
      }
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-end;
    column-gap: rem(12);
    padding: rem(20) rem(24) rem(24);
    border-top: rem(1) solid var(--gray-200);

    button {
      width: rem(240);
    }
  }
}

.submitButton{
  position: absolute;
  right: 20px;
  bottom: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  button{
    &:last-child{
      background-color: var(--primary-700);
      color: #fff !important;
    }
  }
}