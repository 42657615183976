@import '../../styles/functions.scss';

.problems {
  display: flex;
  flex-direction: column;
  row-gap: rem(8);
  padding: rem(10) 1rem;

  .create {
    display: flex;
    align-items: center;
    column-gap: rem(8);
    height: rem(48);
    width: max-content;
    padding: 0 1rem;
    border-radius: rem(6);
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: var(--gray-50);
    }

    &.type {
      margin-left: rem(56);
    }

    &.problem {
      margin-left: rem(88);
    }

    svg path {
      stroke: var(--blue-600);
    }

    p {
      font-weight: 600;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--blue-600);
    }
  }
}
