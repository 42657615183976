@import '../../styles/functions.scss';

.employeesTable {

  :global {
    .ant-table {
      .ant-table-thead {
        .ant-table-cell {
          background-color: var(--gray-25);

          &::before {
            width: 0 !important;
          }

          .ant-table-filter-column {
            justify-content: flex-start;

            span {
              flex: inherit;
              font-weight: 500;
              font-size: rem(12);
              line-height: rem(18);
              color: var(--gray-600);
            }
          }
        }
      }
    }
  }

  .employeesTitle {
    color: var(--gray-900);
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(20);
  }

  .employeesContent {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(20);
    color: var(--gray-600);
  }

  .employeesAction {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    svg {
      padding: rem(10);
      cursor: pointer;
    }
  }
}