@import '../../../../styles/functions';

.modal {
  &_content {
    display: flex;
    flex-direction: column;
    row-gap: rem(12);

    .top {
      display: flex;
      flex-direction: column;

      svg {
        margin-bottom: 1rem;
      }

      p {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(28);
        margin-top: 0;
        margin-bottom: rem(4);
      }

      span {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-600);
      }
    }
  }
}