@import "../../styles/functions.scss";

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(64);
    min-height: rem(64);
    background-color: var(--white);
    padding: 0 1rem;
    border-bottom: rem(1) solid var(--gray-200);

    .title {
      font-weight: 600;
      font-size: rem(20);
      line-height: rem(30);
      color: var(--gray-900);
      margin: 0;
    }
  }

  &_body {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--white);
    margin: 1rem;
    border-radius: rem(12);
    border: rem(1) solid var(--gray-200);

    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: rem(65);
      padding: 0 rem(24);
      border-bottom: rem(1) solid var(--gray-200);

      .filter {
        display: flex;
        align-items: center;
        column-gap: rem(12);

        &_search {
          width: rem(400) !important;
        }

        button {
          display: flex;
          align-items: center;
          column-gap: rem(8);

          &:hover {
            svg path {
              stroke: var(--primary-700);
            }

            span {
              color: var(--primary-700);
            }
          }

          svg path {
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          }

          span {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--gray-700);
            transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
          }
        }
      }

      button {
        display: flex;
        align-items: center;
        column-gap: rem(8);

        span {
          font-weight: 600;
        }
      }
    }

    &_center {
      flex: 1;

      .body {
        overflow-y: auto;
      }
    }

    &_bottom {
      border-top: rem(1) solid var(--gray-200);
      padding: rem(12) 1rem;
    }
  }
}
