@import '../../../../styles/functions';

.modal {

  .time_row {
    margin-bottom: rem(20);

    :global {
      .ant-picker {
        height: rem(44);
        width: 100%;
        border: rem(1) solid var(--gray-300);

        &.ant-picker-status-error {
          border-color: #ff7875;
        }
      }
    }
  }
}