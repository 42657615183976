@import '../../../../styles/functions.scss';

.problem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: rem(48);
  padding-left: rem(104);

  .left {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    p {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--gray-900);
      margin: 0;
    }

    .tag {
      background-color: var(--success-50);
      display: flex;
      align-items: center;
      column-gap: rem(5);
      padding: rem(2) rem(8) rem(2) rem(6);
      border-radius: 1rem;

      span {
        display: block;
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        background-color: var(--success-500);
      }

      p {
        font-weight: 500;
        font-size: rem(12);
        line-height: rem(18);
        color: var(--success-700);
      }
    }
  }

  .right {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    .actions {
      display: flex;
      column-gap: 1rem;

      svg {
        padding: rem(10);
        cursor: pointer;
      }
    }
  }
}
