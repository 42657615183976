@import '../../../../styles/functions.scss';

.type {
  :global {
    .ant-collapse-item.type {
      > .ant-collapse-header {
        display: flex;
        align-items: center;
        height: rem(48);
        border-radius: rem(6) !important;
        padding: 0 1rem;
        margin-left: rem(56) !important;

        &:hover {
          background-color: var(--gray-50);
        }

        .ant-collapse-expand-icon {
          padding-inline-end: rem(8) !important;
        }
      }

      .ant-collapse-content {
        &-box {
          display: flex;
          flex-direction: column;
          row-gap: rem(8);
          padding: rem(8) 0 !important;
        }
      }
    }
  }

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: rem(6);

    &_left {
      display: flex;
      column-gap: 1rem;

      p {
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-900);
        margin: 0;
      }
    }

    &_right {
      display: flex;
      column-gap: 2rem;

      span {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-400);
      }
    }
  }
}

.popover_content {
  display: flex;
  flex-direction: column;
  width: rem(200);
  overflow: hidden;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: rem(12);
    padding: 0 1rem;
    border-radius: 0;

    &:hover {
      background-color: var(--gray-50);
    }

    svg {
      width: 1rem;
      height: 1rem;
    }

    span {
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-700);
    }
  }
}
