@import '../../../../styles/functions.scss';

.profileModal {
  :global {
    .ant-modal-footer {
      justify-content: flex-end;

      button {
        flex: inherit;
      }
    }
  }
}

.profileAuth {
  display: flex;
  align-items: center;
  gap: rem(5);

  :global {
    .ant-form-item {
      margin: 0;
      width: 100%;
    }
  }
}

.upload {
  display: flex;
  gap: rem(20);
  align-items: flex-start;
  margin-top: rem(24);
  position: relative;

  img {
    width: rem(64);
    height: rem(64);
    min-width: rem(64);
    border-radius: 50%;
  }

  :global {
    .ant-form-item {
      width: 100%;
    }

    .ant-avatar {
      width: rem(64);
      height: rem(64);
      min-width: rem(64);
    }
  }

  &_avatar {
    :global {
      .ant-upload-select {
        width: 100% !important;
        height: 126px !important;
        background-color: var(--white) !important;
        border: rem(1) solid var(--gray-200) !important;
      }
    }

    &_in {
      p {
        margin: 0;
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--primary-700);
      }

      span {
        font-weight: 400;
        font-size: rem(12);
        line-height: rem(18);
        color: var(--gray-600);
      }
    }
  }
}
