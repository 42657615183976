@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  // base
  --white: #ffffff;
  --black: #000000;

  // primary
  --primary-50: #f7fffe;
  --primary-500: #76edda;
  --primary-700: #2abfa7;

  // success
  --success-50: #ecfdf3;
  --success-500: #12b76a;
  --success-700: #027a48;

  // gray
  --gray-25: #fcfcfd;
  --gray-50: #f9fafb;
  --gray-100: #f2f4f7;
  --gray-200: #eaecf0;
  --gray-300: #d0d5dd;
  --gray-400: #98a2b3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #1d2939;
  --gray-900: #101828;

  // warning
  --warning-500: #F79009;

  // gray-blue
  --gray-blue-700: #363F72;
  --gray-blue-50: #F8F9FC;

  // blue
  --blue-500: #2E90FA;
  --blue-600: #1570ef;
  --blue-700: #6941C6;

  // error
  --error-50: #fef3f2;
  --error-100: #fee4e2;
  --error-500: #f04438;
  --error-600: #d92d20;
  --error-700: #B42318
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--gray-25);
  margin: 0;
}
