@import '../../../styles/functions';

.evaluations {
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: rem(1) solid var(--gray-200);
  box-shadow: rem(0) rem(1) rem(3) rgba(16, 24, 40, 0.1), rem(0) rem(1) rem(2) rgba(16, 24, 40, 0.06);
  border-radius: rem(12);

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: rem(20) rem(24);
    border-bottom: rem(1) solid var(--gray-200);

    &_left {
      display: flex;
      flex-direction: column;
      row-gap: rem(4);

      h2 {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(28);
        color: var(--gray-900);
        margin: 0;
      }

      p {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-600);
        margin: 0;
      }
    }

    &_right {
      display: flex;

      p {
        font-weight: 600;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--blue-600);
        margin: 0;
        cursor: pointer;
      }
    }
  }

  .body {
    display: flex;
    align-items: center;
    column-gap: rem(40);
    padding: rem(24);

    &.modal {
      align-items: flex-start;
      padding-right: 0;

      .body_left {
        position: sticky;
        top: 0;
      }

      .body_right {
        max-height: 70vh;
        overflow: auto;
        padding-right: rem(24);

        .variant_item {
          display: flex;
          justify-content: space-between;
          padding: rem(12) 0;
          border-bottom: rem(1) solid var(--gray-200);

          p {
            font-weight: 500;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--gray-900);
            margin: 0;
          }
        }
      }
    }

    &_left {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: rem(8);

      h3 {
        font-weight: 600;
        font-size: rem(80);
        line-height: rem(90);
        letter-spacing: -0.02em;
        color: var(--gray-900);
        margin: 0;
        text-align: center;
      }

      p {
        font-weight: 600;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--black);
        margin: 0;
      }
    }

    &_right {
      flex: 1;
      display: flex;
      flex-direction: column;
      row-gap: rem(30);
      padding: rem(10) 0;

      .comment_item {
        display: flex;
        column-gap: 1rem;

        .star {
          display: flex;
          align-items: center;
        }

        .count {
          flex: 1;
          display: flex;
          flex-direction: column;
          row-gap: rem(2);

          :global {
            .ant-progress {
              margin: 0;

              &-outer {
                display: flex;
                height: rem(8) !important;
              }

              &-bg {
                background: var(--success-500) !important;
              }
            }
          }

          p {
            align-self: flex-end;
            font-weight: 600;
            font-size: rem(16);
            line-height: rem(24);
            color: var(--gray-700);
            margin: 0;
          }
        }
      }
    }
  }
}

.modal {

  :global {
    .ant-modal-content {
      background: transparent;
      padding: 0 !important;
      overflow: hidden;
    }
  }
}