@import '../../../../styles/functions';

.collapse {
  :global {
    .folder_collapse {
      .ant-collapse-header {
        display: flex !important;
        align-items: center !important;
        height: rem(48);
        border-radius: rem(6) !important;
        padding: 0 1rem !important;

        &:hover {
          background-color: var(--gray-50);
        }

        .ant-collapse-expand-icon {
          padding-inline-end: rem(8) !important;
        }
      }

      .ant-collapse-content {
        padding-left: 2.5rem;

        &-box {
          display: flex;
          flex-direction: column;
          row-gap: rem(8);
          padding: rem(8) 0 !important;
        }
      }
    }

    .ant-collapse-item-active {
      .ant-collapse-expand-icon {
        svg {
          transform: rotate(90deg);
        }
      }
    }

    .ant-collapse-header-text {
      width: 100% !important;
    }
  }

  .collapsePanel_folder {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_left {
      display: flex;
      align-items: center;
      gap: 16px;

      p {
        color: var(--gray-900);
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &_right {
      display: flex;
      align-items: center;
      gap: 32px;

      span {
        color: var(--gray-400);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .employee {
    display: flex;
    align-items: center;
    height: rem(48);

    .name {
      display: flex;
      align-items: center;
      column-gap: 1rem;
      width: 30%;

      p {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-900);
        margin: 0;
      }
    }

    .phone {
      display: flex;
      align-items: center;
      width: 30%;

      span {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-400);
      }

      p {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-500);
        margin: 0;
      }
    }

    .login {
      display: flex;
      align-items: center;
      width: 30%;

      span {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-400);
      }

      p {
        font-weight: 500;
        font-size: rem(16);
        line-height: rem(24);
        color: var(--gray-500);
        margin: 0;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      column-gap: 1rem;
      margin-left: auto;
      width: 10%;

      svg {
        padding: rem(10);
        cursor: pointer;
      }
    }
  }

  .addAdmin {
    display: flex;
    align-items: center;
    gap: 16px;
    cursor: pointer;

    p {
      color: var(--blue-600);
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
    }
  }
}

.popover {
  display: flex;
  flex-direction: column;
  width: rem(200);
  overflow: hidden;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: rem(12);
    padding: 0 1rem;
    border-radius: 0;

    &:hover {
      background-color: var(--gray-50);
    }

    svg {
      width: 1rem;
      height: 1rem;
    }

    p {
      font-weight: 500;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-700);
      margin: 0;
    }
  }
}
