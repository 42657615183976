@import '../../../styles/functions';

.problem_classes {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: rem(1) solid var(--gray-200);
  box-shadow: rem(0) rem(1) rem(3) rgba(16, 24, 40, 0.1), rem(0) rem(1) rem(2) rgba(16, 24, 40, 0.06);
  border-radius: rem(12);

  .header {
    display: flex;
    flex-direction: column;
    row-gap: rem(4);
    padding: rem(20) rem(24);
    border-bottom: rem(1) solid var(--gray-200);

    h2 {
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(28);
      color: var(--gray-900);
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-600);
      margin: 0;
    }
  }

  .body {
    display: flex;
    padding: rem(24);
  }
}