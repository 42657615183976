@import '../../styles/functions';

.table {

  :global {
    .ant-table-thead {
      .ant-table-cell {
        background-color: var(--gray-25);

        &::before {
          width: 0 !important;
        }

        .ant-table-filter-column {
          justify-content: flex-start;

          span {
            flex: inherit;
            font-weight: 500;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-600);
          }
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row:hover {
        cursor: pointer;

        .ant-table-cell {
          background-color: var(--gray-25) !important;
        }
      }
    }
  }

  .name.new {
    position: relative;
    padding-left: rem(20);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: rem(8);
      height: rem(8);
      border-radius: 50%;
      background-color: var(--error-500);
    }
  }
}