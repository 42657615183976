@import '../../../../styles/functions.scss';

.modal {
  .auth {
    display: flex;
    align-items: center;
    gap: rem(5);

    :global {
      .ant-form-item {
        margin: 0;
        width: 100%;
      }
    }
  }

  .change_password {
    margin-top: rem(8);
  }

  .btns {
    display: flex;
    align-items: center;
    margin-top: rem(32);
    gap: rem(12);

    button {
      width: 100%;
      font-weight: 600;
      font-size: rem(16);
      line-height: rem(18);

      &:last-child {
        background-color: var(--primary-700);
        color: var(--white);
        border: rem(1) solid var(--primary-700);
      }
    }
  }
}