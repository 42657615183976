@import '../../styles/functions.scss';

.layout {
  display: flex;
  height: 100vh;
  max-height: 100vh;

  &_navbar {
    display: flex;
    flex-direction: column;
    row-gap: rem(16);
    width: rem(280);
    min-width: rem(280);
    background-color: var(--white);
    border-right: rem(1) solid var(--gray-200);
    padding: 0 rem(10);
    box-sizing: border-box;

    &_top {
      display: flex;
      align-items: center;
      column-gap: rem(20);
      padding: rem(20) rem(18);
      border-bottom: rem(1) solid var(--gray-200);

      //svg {
      //  width: rem(40);
      //  height: rem(22);
      //}
      //
      //span {
      //  font-size: rem(18);
      //  line-height: rem(24);
      //  font-weight: bold;
      //  color: var(--gray-800);
      //}

      img {
        width: rem(200);
      }
    }

    &_center {
      flex: 1;
      display: flex;
      flex-direction: column;

      a {
        display: flex;
        padding: rem(8) rem(12);
        column-gap: rem(12);
        row-gap: rem(4);
        text-decoration: none;
        transition: 0.2s;
        border-radius: rem(6);

        &.last {
          margin-top: auto;
        }

        &.active {
          background-color: var(--gray-50);

          svg {
            path {
              stroke: var(--primary-700);
            }
          }

          span {
            color: var(--primary-700);
          }
        }

        &:hover {
          svg {
            path {
              stroke: var(--primary-700);
            }
          }

          span {
            color: var(--primary-700);
          }
        }

        svg path {
          transition: 0.2s;
        }

        span {
          font-weight: 600;
          font-size: rem(16);
          line-height: rem(24);
          color: var(--gray-700);
          transition: 0.2s;
        }
      }
    }

    &_bottom {
      padding: 0 rem(16) 0 rem(16);
    }
  }

  &_help_center {
    padding: 0 rem(18);
    display: flex;
    align-items: center;
    text-decoration: none;
    span {
      color: #25A3E1;
      font-size: rem(16);
      font-style: normal;
      font-weight: 600;
      line-height: rem(24);
      //margin-left: rem(12);
    }
  }
}
