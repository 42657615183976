@import '../../../../styles/functions';

.organizations_or_cities {
  display: flex;
  flex-direction: column;
  row-gap: rem(20);
  background: var(--white);
  border: rem(1) solid var(--gray-200);
  box-shadow: rem(0) rem(1) rem(3) rgba(16, 24, 40, 0.1), rem(0) rem(1) rem(2) rgba(16, 24, 40, 0.06);
  border-radius: rem(12);
  padding: rem(24);

  &.modal {
    border: none;
    box-shadow: none;
    padding: 0;

    .header {
      padding-right: 2rem;
    }

    .footer {
      justify-content: flex-start;
    }
  }

  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &_left {
      display: flex;
      align-items: center;
      column-gap: rem(8);

      h2 {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(28);
        color: var(--gray-900);
        margin: 0;
      }

      .dropdown_label {
        display: flex;
        align-items: center;
        column-gap: rem(8);
        cursor: pointer;

        span {
          font-weight: 600;
          font-size: rem(18);
          line-height: rem(24);
          color: var(--blue-500);
        }
      }
    }

    &_right {
      :global {
        .ant-select {
          width: rem(260);
        }
      }
    }
  }

  .table {
    display: flex;

    :global {
      .ant-spin-nested-loading {
        width: 100%;
      }
    }

    table {
      thead {
        background: transparent !important;

        th {
          font-weight: 500 !important;
          font-size: rem(12);
          line-height: rem(18);
          color: var(--gray-600) !important;
          background: transparent;
          border-top: none;
          border-bottom: rem(1) solid var(--gray-200) !important;

          &:before {
            display: none;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    justify-content: flex-end;

    p {
      margin: 0;
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(24);
      color: var(--blue-500);
      cursor: pointer;
    }
  }
}

.modal {
  :global {
    .ant-modal-content {
      min-height: rem(743);
      overflow: hidden;
    }
  }
}