@import '../../styles/functions.scss';

.confirm {
  :global {
    .ant-btn-dangerous {
      background-color: var(--error-600);

      &:hover {
        background-color: var(--error-500) !important;
      }
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    p {
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(28);
      color: var(--gray-900);
      margin: 0;
    }
  }
}
