@import "../../../../styles/functions.scss";

.organization_popup {
  :global {
    .ant-select-item-option-disabled {
      color: initial;
    }
  }

  .create_item {
    display: flex !important;
    align-items: center;
    column-gap: rem(8);

    svg path {
      stroke: var(--blue-600);
    }

    span {
      font-weight: 600;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--blue-600);
    }
  }
}
