@import '../../../../styles/functions.scss';

.settingsCenter {
  height: calc(100vh - 4rem);
  padding: rem(16) rem(23) rem(16) rem(9);
  overflow: auto;
  box-sizing: border-box;

  &_body {
    background-color: var(--white);
    border-radius: rem(12);
    border: rem(1) solid var(--gray-200);
    padding: rem(16);

    &_header {
      padding: rem(6) 0;
      margin-bottom: rem(16);

      span {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(28);
        color: var(--gray-900);
      }
    }

    &_main {
      p {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-700);
      }

      &_child {
        display: flex;
        border-top: rem(1) solid var(--gray-200);
        padding-bottom: rem(24);

        &_left {
          width: 246px;

          &_stars {
            height: 72px;
            display: flex;
            align-items: center;
          }
        }

        &_right {
          :global {
            .ant-col {
              margin: 0 !important;
            }

            .ant-form-item-control-input-content {
              .ant-input {
                width: 480px;
              }

              display: flex;
              align-items: center;
              gap: rem(8);

              svg {
                cursor: pointer;
              }

              button {
                border: none;
                box-shadow: none;
                display: flex;
                align-items: center;
                gap: rem(8);
                padding: 0;

                span {
                  font-weight: 600;
                  font-size: rem(16);
                  line-height: rem(24);
                  color: var(--blue-600);
                }

                &:focus-visible {
                  outline: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.btn {
  border: none;
  outline: none;
  background-color: var(--white);
  display: flex;
  align-items: center;
  gap: rem(8);
  cursor: pointer;

  span {
    font-weight: 600;
    font-size: rem(16);
    line-height: rem(24);
    color: var(--blue-600);
  }
}

.create {
  display: flex;
  align-items: center;
  gap: rem(8);
  margin-bottom: rem(12);
  width: rem(480);

}

.editing {
  display: flex;
  align-items: center;
  width: 480px;
  gap: 10px;
}

.delete {
  svg {
    path {
      stroke: var(--error-500);
    }
  }
}

.update {
  svg {
    path {
      stroke: var(--white);
    }
  }
}
