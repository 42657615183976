@import '../../../../styles/functions.scss';

.modal {
  .auth {
    display: flex;
    align-items: center;
    gap: rem(5);

    :global {
      .ant-form-item {
        margin: 0;
        width: 100%;
      }
    }
  }

  .change_password {
    margin-top: rem(8);
  }
}