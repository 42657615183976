@import '../../../../styles/functions.scss';

.modal {

  &_content {
    display: flex;
    flex-direction: column;
    row-gap: rem(12);

    &_top {
      display: flex;
      flex-direction: column;

      svg {
        margin-bottom: 1rem;
      }

      span {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(28);
        color: var(--gray-900);
        margin-bottom: rem(4)
      }

      p {
        font-weight: 400;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-600);
        margin: 0;
      }
    }

    &_bottom {

      .images_cont {
        display: flex;
        flex-direction: column;
        row-gap: rem(6);
        margin-bottom: rem(6);

        &_item {
          display: flex;
          align-items: center;
          column-gap: 1rem;
          height: rem(72);
          border: rem(1) solid var(--gray-200);
          border-radius: rem(12);
          padding: 0 1rem;


          .info {
            display: flex;
            flex-direction: column;

            p {
              font-weight: 500;
              font-size: rem(14);
              line-height: rem(20);
              color: var(--gray-700);
              margin: 0;
            }

            label {
              display: flex;
              position: relative;

              span {
                font-weight: 600;
                font-size: rem(12);
                line-height: rem(18);
                color: var(--blue-600);
                cursor: pointer;
              }

              div {
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
                width: rem(42);
                height: rem(15);

                img {
                  width: 100%;
                  height: 100%;
                }

                //display: none;
              }
            }
          }

          .delete {
            display: flex;
            margin-left: auto;

            svg {
              cursor: pointer;
              padding: rem(10);
            }
          }
        }
      }
    }
  }

}