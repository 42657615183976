@import '../../../../styles/functions.scss';

.auth_form {
  display: flex;
  flex-direction: column;
  row-gap: rem(48);
  padding: rem(48) rem(64);
  border: rem(1) solid var(--gray-200);
  box-shadow: 0px 46px 68px rgba(179, 179, 179, 0.08),
  0px 10.2747px 15.1887px rgba(179, 179, 179, 0.0476886),
  0px 3.05905px 4.52207px rgba(179, 179, 179, 0.0323114);
  border-radius: 1rem;
  z-index: 1;
  background-color: var(--white);

  &_title {
    display: flex;
    align-items: center;
    column-gap: rem(24);

    //h1 {
    //  margin: 0;
    //  font-size: rem(20);
    //  line-height: rem(24);
    //  color: var(--gray-800);
    //}

    img {
      width: rem(250);
    }
  }

  &_body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;

    h2 {
      font-weight: 600;
      font-size: rem(36);
      line-height: rem(44);
      letter-spacing: -0.02em;
      color: var(--gray-900);
      margin: 0;
    }

    form {
      width: rem(360);

      button {
        width: 100%;
        margin-top: rem(24);
      }
    }
  }
}
