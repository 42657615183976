@import '../../../styles/functions';

.pie_chart {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .total {
    position: absolute;
    font-weight: 700;
    font-size: rem(30);
    line-height: rem(38);
    color: var(--gray-900);
  }
}