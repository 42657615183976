@import "../../styles/functions.scss";

.troubles {
  :global {
    .ant-table-thead {
      .ant-table-cell {
        &::before {
          width: 0 !important;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row:hover {
        cursor: pointer;

        .ant-table-cell {
          background-color: var(--gray-25) !important;
        }
      }
    }
  }

  &_status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(141);
    height: rem(28);
    border-radius: rem(16);
    border: rem(1.5) solid transparent;
    font-weight: 500;
    font-size: rem(14);
    line-height: rem(20);
    margin: 0 auto;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1rem;

    svg {
      padding: rem(10);
      cursor: pointer;
    }
  }
}

.tabs {
  :global {
    .ant-tabs-nav {
      margin-bottom: 0;
      margin-left: rem(16);
    }
  }
}