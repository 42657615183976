@import '../../styles/functions.scss';

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;

  &_top {
    display: flex;
    flex-direction: column;
    row-gap: rem(8);
    padding: rem(12) rem(24);
    max-height: 70vh;
    overflow: auto;

    .chats {
      display: flex;
      flex-direction: column;
      row-gap: rem(8);

      .event {
        display: flex;
        justify-content: space-between;
        column-gap: 2rem;

        span {
          font-weight: 400;
          font-size: rem(12);
          line-height: rem(18);
          color: var(--gray-400);
        }
      }

      .mine {
        display: flex;
        flex-direction: column;
        row-gap: rem(6);
        margin-left: auto;
        max-width: 80%;
        width: max-content;

        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 2rem;

          p {
            font-weight: 500;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--gray-700);
            margin: 0;
          }

          span {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-600);
          }
        }

        .text {
          padding: rem(10) rem(14);
          background: var(--white);
          border-radius: rem(8) 0 rem(8) rem(8);
          border: rem(1) solid var(--gray-200);

          p {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-900);
            margin: 0;
          }
        }
      }

      .others {
        display: flex;
        column-gap: rem(12);
        max-width: 80%;
        width: max-content;

        &_left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: rem(40);
          min-width: rem(40);
          height: rem(40);
          border-radius: 50%;
          background: var(--gray-200);

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        &_right {
          display: flex;
          flex-direction: column;
          row-gap: rem(6);

          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 2rem;

            p {
              font-weight: 500;
              font-size: rem(14);
              line-height: rem(20);
              color: var(--gray-700);
              margin: 0;
            }

            span {
              font-weight: 400;
              font-size: rem(12);
              line-height: rem(18);
              color: var(--gray-600);
            }
          }

          .text {
            padding: rem(10) rem(14);
            background: var(--gray-200);
            border-radius: 0 rem(8) rem(8) rem(8);

            p {
              font-weight: 400;
              font-size: rem(12);
              line-height: rem(18);
              color: var(--gray-900);
              margin: 0;
            }
          }
        }
      }
    }
  }

  &_bottom {
    display: flex;
    align-items: center;
    column-gap: rem(8);
    position: sticky;
    bottom: 0;
    padding: rem(8);
    background: var(--white);
    border-top: rem(1) solid var(--gray-200);
    margin-top: auto;

    .item {
      flex: 1;

      input {
        height: rem(40);
      }
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}