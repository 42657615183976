@import './functions.scss';

// div
.f_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.d-n {
  display: none !important;
}

// flex
.d-f {
  display: flex;
}

.ai-c {
  align-items: center;
}

.jc-sb {
  justify-content: space-between;
}

// cursor
.cursor-p {
  cursor: pointer;
}


// ant-problem-accident-modal
.ant-modal {
  &-content {
    border-radius: rem(12) !important;
    padding: rem(24) !important;

    .ant-modal-close {
      top: rem(26);
      right: rem(26);
    }

    .ant-modal-header {
      margin-bottom: rem(20);

      .ant-modal-title {
        font-weight: 600;
        font-size: rem(18);
        line-height: rem(28);
        color: var(--gray-900);
      }
    }

    .ant-modal-footer {
      display: flex;
      column-gap: rem(12);
      margin-top: 2rem;

      button {
        margin: 0 !important;
        flex: 1;

        span {
          font-weight: 600;
        }
      }
    }
  }
}

// ant-form
.ant-form {
  &-item {
    margin-bottom: rem(20);

    &:last-of-type {
      margin-bottom: 0;
    }

    &-label {
      padding-bottom: rem(6) !important;

      label {
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(20);
        color: var(--gray-700);

        &::before {
          display: none !important;
        }
      }
    }
  }
}

//form item has error
.ant-form-item-has-error {
  .input-mask {
    border-color: #ff4d4f;
  }
}

// ant-pagination
.ant-pagination {
  display: flex;

  li {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: rem(40) !important;
    min-width: rem(40) !important;
    margin: 0 !important;
    transition: 0.2s;

    &:hover {
      background-color: var(--gray-100) !important;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    width: rem(60);
    border: rem(1) solid var(--gray-300) !important;
    transition: 0.2s;

    &:hover {
      background-color: var(--gray-100);
    }
  }

  .ant-pagination-prev {
    border-radius: rem(8) 0 0 rem(8);
  }

  .ant-pagination-next {
    border-radius: 0 rem(8) rem(8) 0;
    border-left: none !important;
  }

  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    width: rem(40);
    height: rem(40);
    border: rem(1) solid var(--gray-300) !important;
    border-radius: 0;
    border-left: none !important;
    transition: 0.2s;

    &:hover {
      background-color: var(--gray-100);
    }
  }

  > .ant-pagination-item {
    width: rem(35);
    border-radius: 0;
    border: rem(1) solid var(--gray-300);
    border-left: none;

    a {
      font-weight: 400;
      font-size: 1rem;
      line-height: 175%;
      color: var(--gray-700);
    }

    &-active {
      background-color: var(--gray-100);

      a {
        font-weight: 600;
      }

      &:hover {
        border-color: var(--gray-300);

        a {
          color: var(--gray-700);
        }
      }
    }
  }

  .ant-pagination-disabled:hover {
    background-color: initial !important;
  }
}

// ant-table
.ant-table {
  table {
    border-radius: 0 !important;
  }

  &-thead {
    background-color: var(--gray-25);

    .ant-table-cell {
      border-radius: 0 !important;
      border-bottom: rem(1) solid var(--gray-200) !important;
      border-top: rem(1) solid var(--gray-200);
      padding: rem(12) 1rem !important;
      font-weight: 500 !important;
      font-size: rem(12);
      line-height: rem(18);
      color: var(--gray-600) !important;
    }
  }

  &-tbody {
    .ant-table-row:hover {
      .ant-table-cell {
        background-color: initial !important;
      }
    }

    .ant-table-cell {
      padding: 0 1rem !important;
      height: rem(52) !important;
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-600);
    }
  }
}

// ant-popover
.ant-popover {
  .ant-popover-inner {
    padding: 0;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08),
    0 4px 6px -2px rgba(16, 24, 40, 0.03);
    overflow: hidden;
  }
}

// 'rejected','deleted'
// status
.status {
  &_new {
    color: var(--gray-blue-700) !important;
    border-color: var(--gray-blue-700) !important;
  }

  &_process {
    color: var(--warning-500) !important;
    border-color: var(--warning-500) !important;
  }

  &_completed, &_closed {
    color: var(--success-500) !important;
    border-color: var(--success-500) !important;
  }

  &_rejected, &_deleted, &_user_rejected {
    color: var(--error-500) !important;
    border-color: var(--error-500) !important;
  }
}