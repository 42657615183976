@import "../../../../styles/functions.scss";

.employee {
  display: flex;
  align-items: center;
  height: rem(48);
  padding-left: rem(64);

  .name {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    width: 30%;

    p {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--900);
      margin: 0;
    }
  }

  .phone {
    display: flex;
    align-items: center;
    width: 30%;

    span {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--400);
    }

    p {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--gray-500);
      margin: 0;
    }
  }

  .login {
    display: flex;
    align-items: center;
    width: 30%;

    span {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--400);
    }

    p {
      font-weight: 500;
      font-size: rem(16);
      line-height: rem(24);
      color: var(--gray-500);
      margin: 0;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
    margin-left: auto;
    width: 10%;

    svg {
      padding: rem(10);
      cursor: pointer;
    }
  }
}