@import '../../../../styles/functions.scss';

.modal {

  #login, #password {
    -webkit-text-security: disc !important;
  }

  :global {
    .ant-checkbox-wrapper {
      margin-top: rem(20) !important;
    }
  }

  .btns {
    display: flex;
    gap: rem(5);

    :global {
      .ant-form-item {
        width: 100%;
        margin: 0 !important;

      }
    }
  }
}
