@import '../../styles/functions';

.tag {
  display: flex;
  align-items: center;
  column-gap: rem(4);

  &.is_loading svg {
    width: rem(12) !important;
  }

  &:hover {
    svg {
      width: rem(12);
    }
  }

  svg {
    width: 0;
    height: rem(12);
    transition: 0.3s ease-in-out;
    cursor: pointer;
  }
}