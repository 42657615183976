@import '../../../../../styles/functions.scss';

.form {
  :global {
    .ant-upload-wrapper {
      .ant-upload-select {
        height: rem(74) !important;
        width: 100% !important;
        border: rem(1) solid var(--gray-300) !important;
        background-color: transparent !important;
        margin: 0 !important;
        transition: 0.2s;

        &:hover {
          border-color: var(--primary-700) !important;
        }

        .support {
          display: flex;
          flex-direction: column;
          row-gap: rem(4);

          p {
            font-weight: 600;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--primary-700);
            margin: 0;
          }

          span {
            font-weight: 400;
            font-size: rem(12);
            line-height: rem(18);
            color: var(--gray-600);
          }
        }

        img {
          height: 3rem;
          width: 3rem;
        }
      }
    }

    .ant-form-item-has-error {
      .ant-upload {
        border-color: #ff4d4f !important;
      }
    }
  }
}
