@import '../../styles/functions';

.skleton {
  .paragraph {
    display: flex;
    flex-direction: column;
    row-gap: rem(24);
    margin: 0;

    li {
      height: rem(32) !important;
      border-radius: rem(6) !important;
      margin: 0 !important;
      width: 100% !important;
      opacity: 0.4;
    }
  }
}
