@import '../../../../styles/functions.scss';

.form {
  &_login {
    display: flex;
    gap: rem(5);

    :global {
      .ant-form-item {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  .change_password {
    margin-top: rem(8);
  }
}