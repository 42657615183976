@import '../../styles/functions';

.layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: rem(64);
    min-height: rem(64);
    background-color: var(--white);
    padding: 0 1rem;
    border-bottom: rem(1) solid var(--gray-200);

    .title {
      font-weight: 600;
      font-size: rem(20);
      line-height: rem(30);
      color: var(--gray-900);
      margin: 0;
    }
  }

  &_body {
    flex: 1;
    padding: 1rem;
    overflow: auto;

    :global {
      path {
        outline: none;
      }
    }
  }
}