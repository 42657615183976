.auth {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: var(--white);
  position: relative;

  .copyright {
    font-weight: 400;
    font-size: rem(14);
    line-height: rem(20);
    color: var(--gray-600);
    margin-top: 4vh;
    margin-bottom: 11vh;
  }

  .roles {
    display: flex;
    column-gap: 1.5rem;
    padding-bottom: 1rem;

    span {
      display: flex;
      cursor: pointer;
      transition: 0.2s;
      color: var(--gray-900);

      &:hover {
        color: var(--primary-700);
      }
    }
  }

  img.bg {
    position: absolute;
    width: 100%;
    bottom: 0;
    pointer-events: none;
  }
}
