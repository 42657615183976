@import '../../styles/functions.scss';

.subsidinartTable {
    &_name {
        color: var(--gray-900);
        font-weight: 500;
        font-size: rem(14);
        line-height: rem(20);
    }

    &_actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        svg {
            padding: 10px;
            cursor: pointer;
        }
    }
}