@import '../../../styles/functions';

.problems {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--white);
  border: rem(1) solid var(--gray-200);
  box-shadow: rem(0) rem(1) rem(3) rgba(16, 24, 40, 0.1), rem(0) rem(1) rem(2) rgba(16, 24, 40, 0.06);
  border-radius: rem(12);

  .header {
    display: flex;
    flex-direction: column;
    row-gap: rem(4);
    padding: rem(20) rem(24);
    border-bottom: rem(1) solid var(--gray-200);

    h2 {
      font-weight: 600;
      font-size: rem(18);
      line-height: rem(28);
      color: var(--gray-900);
      margin: 0;
    }

    p {
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-600);
      margin: 0;
    }
  }

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    padding: rem(24);

    .legend {
      display: flex;
      justify-content: space-between;

      &_item {
        display: flex;
        flex-direction: column;

        .label {
          display: flex;
          align-items: center;
          column-gap: rem(8);

          span {
            display: block;
            width: rem(8);
            height: rem(8);
            border-radius: 50%;
            &.new{
              background: #89CFF0;
            }
            &.completed {
              background: #12B76A;
            }

            &.closed {
              background: #6938EF;
            }

            &.progress {
              background: #F79009;
            }

            &.rejected {
              background: #F04438;
            }
            &.userRejected{
              background: #002244;
            }
          }

          p {
            font-weight: 400;
            font-size: rem(14);
            line-height: rem(20);
            color: var(--gray-700);
            margin: 0;
          }
        }

        .value {
          font-weight: 600;
          font-size: rem(18);
          line-height: rem(28);
          color: var(--gray-900);
          margin: 0;
          padding-left: 1rem;
        }
      }
    }
  }
}