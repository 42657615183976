@import "../../styles/functions.scss";

.profile {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_left {
    display: flex;
    align-items: center;
    gap: rem(12);
    cursor: pointer;

    h5 {
      margin: 0;
      font-weight: 600;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-700);
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: rem(14);
      line-height: rem(20);
      color: var(--gray-700);
    }

    img {
      width: rem(40);
      min-width: rem(40);
      height: rem(40);
      object-fit: cover;
      border-radius: 50%;
    }
  }

  &_right {
    cursor: pointer;
  }
}
